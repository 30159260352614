// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import {
  Button,
  Brands,
  Card,
  Hero,
  hooks,
  Section,
  Table,
  text,
} from '@toggl/ui'
import { colors, media } from '@toggl/style'

import IndexLayout from 'layouts'
import LayoutTrack from 'components/LayoutTrack'
import MDX from 'components/mdx'

import brandsImg from 'images/uploads/brands-desktop.png'
import brandsImgMobile from 'images/uploads/brands-mobile.png'
import ogImage from 'images/uploads/toggl-track-pricing-plans.jpg'

// $FlowFixMe
import yamlData from '../../../data/track/pages/pricing.yml'
import Signup from 'components/Signup'

export default function PricingPage() {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const {
    sections: { brands, hero, billingSwitch, pricing, plans },
  } = yamlData

  const HeroComponent = ({ children }) => {
    return (
      <Hero.Wrapper>
        {children}
        <HeroContentWrapper>
          <HeroTitle centered>
            <MDX>{hero.heading}</MDX>
          </HeroTitle>
          <MDX p={HeroText}>{hero.text}</MDX>
          <MDX p={HeroText}>{hero.footer}</MDX>
        </HeroContentWrapper>
      </Hero.Wrapper>
    )
  }

  const [billing, setBilling] = React.useState(billingSwitch.left.id)
  const onBillingChange = (billing) => {
    setBilling(billing)
  }

  const BrandsComponent = React.useMemo(
    () => (
      <Brands
        title={brands.title}
        subtitle={brands.subtitle}
        imgSrc={brandsImg}
        mobileImgSrc={brandsImgMobile}
      />
    ),
    [brands.subtitle, brands.title]
  )

  const StyledSignup = React.useMemo(
    () => <Signup signupBackground={colors.pink} />,
    []
  )

  return (
    <IndexLayout
      title={yamlData.title}
      description={yamlData.description}
      ogImage={ogImage}
    >
      <LayoutTrack Hero={HeroComponent}>
        <SectionBlock background={colors.darkPurple}>
          <PricingWrapper>
            <PricingCardsWrapper>
              {(isDesktop ? pricing.desktopOrder : pricing.mobileOrder).map(
                (plan) => (
                  <CardPricing
                    {...pricing.cards[plan]}
                    discount={billing === 'annual'}
                    key={`pricing_card_${plan}`}
                    expandText={pricing.expandCardText}
                  />
                )
              )}
            </PricingCardsWrapper>
            <BillingSwitchWrapper>
              <BillingText>{billingSwitch.label}</BillingText>
              <div>
                <Button.Toggle
                  promo={billingSwitch.promo}
                  defaultActive={
                    billing === billingSwitch.left.id ? 'left' : 'right'
                  }
                  labels={[billingSwitch.left.label, billingSwitch.right.label]}
                  onClick={(active) => {
                    onBillingChange(
                      active === 'left'
                        ? billingSwitch.left.id
                        : billingSwitch.right.id
                    )
                  }}
                />
              </div>
            </BillingSwitchWrapper>
          </PricingWrapper>
        </SectionBlock>
        <SkewedDivider color={colors.fadedPurple}>
          <div />
        </SkewedDivider>
        <Section.Block background={colors.fadedPurple}>
          <TablePlanComparison {...plans} />
        </Section.Block>
        {isDesktop && (
          <Section.Skewed backgroundColor={colors.fadedPurple}>
            {BrandsComponent}
          </Section.Skewed>
        )}
        {!isDesktop && (
          <Section.Block background={colors.beige}>
            {BrandsComponent}
          </Section.Block>
        )}
        {isDesktop && (
          <Section.Skewed
            color={colors.fadedPurple}
            backgroundColor={colors.beige}
          >
            {StyledSignup}
          </Section.Skewed>
        )}
        {!isDesktop && StyledSignup}
      </LayoutTrack>
    </IndexLayout>
  )
}

const SectionBlock = styled(Section.Block)`
  padding: 60px 30px;
  padding-top: 0;

  ${media.mq[1]} {
    padding-top: 60px;
  }
`

const SkewedDivider = styled(Section.Skewed)`
  ${media.mq[1]} {
    height: 350px;
  }
`

const TablePlanComparison = styled(Table.PlanComparison)`
  margin: 20px 0;
  margin-top: -50px;

  ${media.mq[1]} {
    margin-top: -70px;
  }
`

const PricingWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;

  flex-direction: column;
  ${media.mq[1]} {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }
`

const HeroContentWrapper = styled(Hero.ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 0 !important;
`

const HeroTitle = styled(Hero.Title)`
  p {
    ${text.heading4};

    ${media.mq[1]} {
      ${text.heading3};
    }

    color: ${({ theme }) => theme.primary} !important;
  }
`

const HeroText = styled(Hero.Text)`
  text-align: center;

  b {
    font-weight: 700;
  }
`

const BillingSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 40px;

  ${media.mq[1]} {
    margin-top: 0;
    margin-bottom: 130px;
  }
`

const BillingText = styled.div`
  ${text.heading5};

  position: relative;
  top: 30px;

  ${media.mq[1]} {
    ${text.paragraph2};
    top: 10px;
  }

  color: ${({ theme }) => theme.accent};
  text-align: center;
`

const PricingCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 25px;

  ${media.mq[2]} {
    margin: 0;
    flex-direction: row;
  }
`

const CardPricing = styled(Card.Pricing)`
  &:first-child {
    margin-bottom: 30px;
  }

  &:not(:first-child) {
    margin-bottom: 10px;
  }

  ${media.mq[2]} {
    margin: 0;

    &:not(:last-child) {
      margin-right: 25px;
    }
  }
`
